<template>
  <div id="userlist"
    :class="[
      position,
      { hidden: !isVisible || players.length == 0 },
      { darkmode: darkmode }]">
    <div class="top">{{ sectionTitle }}</div>
    <div class="list">
      <player-avatar v-for="player in players.slice(0, limit)" :player="player" :key="player.id" :scale="1.5" :showUsername="true" :horizontal="true"/>
    </div>
    <div class="bottom">
      <template v-if="players.length > limit">
        <div class="and-more">
          {{ $t('system.screenMoreUsers',players.length - limit) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PlayerAvatar from '../PlayerAvatar.vue';

export default {
  components: { PlayerAvatar },
  props: {
    title: {
      type: String,
    },
    type: {
      // Puede ser 'queue' para mostrar solo los jugadores en fila de espera
      // Puede ser 'active' para mostrar los jugadores que NO están en fila
      // O se deja vacia para mostar todos los jugadores
      type: String,
      default: ""
    },
    limit: {
      type: Number,
      default: 5
    },
    darkmode: {
      type: Boolean,
      default: false
    },
    autohide: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isVisible: false,
      secToHide: 5,
      position: "left",
      timer: undefined,
    };
  },
  sockets: {
    playerJoined() {
      this.isVisible = true;
      if(this.autohide){
        this.timer = setTimeout(() => {
          this.isVisible = false;
        }, this.secToHide * 1000);
      }
    }
  },
  computed: {
    sectionTitle(){
      if(this.title != undefined && this.title != ""){
        return this.title;
      }
      return this.$t("system.screenUsersOnline");
    },
    players() {
      if (this.type == "active") return this.$store.state.space.players.filter(x => !x.queue)
      if (this.type == "queue") return this.$store.state.space.players.filter(x => x.queue)
      return this.$store.state.space.players
    },
    size() {
      if (this.$store.state.space.players.length == 0) return "large"
      return "small"
    }
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

$width: 190px;


#userlist {
  @include blurBox();
  margin: 0;
  position: absolute;
  min-height: 116px;
  z-index: 999999;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $width;
  padding: 8px 4px 13px;
  transition: all .7s cubic-bezier(.21, -0.22, .3, 1);

  .playerAvatarContainer {
    margin: 10px;
  }

  .top {
    margin-bottom: 1em;
    text-align: center;
    font-size: 22px;
  }

  .bottom {
    display: flex;
    justify-content: center;

    .and-more {
      width: 100px;
      text-align: center;
      line-height: 1.2;
    }
  }

  &.left {
    left: calc(($width/2) - 1px);

    &.hidden {
      left: -$width;
    }
  }

  &.right {
    left: calc(100vw - ($width/2));

    &.hidden {
      left: calc(100vw + $width);
    }
  }

  &.darkmode {
    background-color: rgba($black-bis,0.8);
    color: $white-bis;
  }
}
</style>